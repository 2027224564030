.chat-container {
  width: 20vw!important;
  height: 100vh!important;
  background: rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0px!important;
  top: 0px!important;
  .chat-wrap {
    width: 60vw;
    max-width: 800px;
    height: 100vh!important;
    background: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 0px!important;
    border-top-right-radius: 10px!important;
    border-bottom-right-radius: 10px!important;
    box-shadow: 0 10px 20px rgba(0,0,0,0.5);
    box-sizing: border-box;
    padding: 0px 0px 20px 0!important;
    h2{
      height: 45px;
      line-height: 45px;
      border-bottom: 1px solid #dadada;
      text-align: left;
    padding-left: 19px;
    }
    .chat-message-wrap {
      flex-grow: 1;
      overflow-y: auto;
      border-bottom: 1px solid #eee;
    }
    .chat-message-box {
      height: 100px;
      box-sizing: border-box;
      padding: 0 5px;
      >textarea {
        padding: 5px 20px;
        height: 100%;
        width: 100%;
        resize: none;
      }
    }
  }
  .chat-disabled {
    height: 155px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    flex-shrink: 0;
  }
  .closeSidebar
  {
    color: #010101;
    position: absolute;
    left: 360px;
    top: 8px!important;
    font-size: 20px;
    cursor: pointer;
    top: 6px;
    background-color: #fff!important;
    border: 0px;
    padding: 0px;
    font-family: sans-serif;
  }

}