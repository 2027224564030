.video-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
  width: 100%;
}

.chathover{
  display: none;
}
.btn-chat-custom:hover img.chatdefault {
  display: none;
}
.btn-chat-custom:hover img.chathover {
  display: block;
}
.chatHoverClass img.chathover{
  display: block;
} 
.chatHoverClass img.chatdefault{
  display: none;
} 

button.btn-chat-custom {border:1px solid #fff!important}
button.btn-chat-custom.chatHoverClass,button.btn-chat-custom:hover{border:1px solid #40a9ff!important}


.noteshover{
  display: none;
}
.btn-notes-custom:hover img.notesdefault {
  display: none;
}
.btn-notes-custom:hover img.noteshover {
  display: block;
}
.notesHoverClass img.noteshover{
  display: block;
} 
.notesHoverClass img.notesdefault{
  display: none;
} 
button.btn-notes-custom {border:1px solid #fff!important}
button.btn-notes-custom.notesHoverClass,button.btn-notes-custom:hover{border:1px solid #40a9ff!important}